<template>
<div class="d-flex flex-column flex-root" >
    <div class="d-flex flex-column flex-lg-row flex-row-fluid bg-white">       
       <router-view></router-view>   
    </div>
</div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>

